export default function About() {
  return (
    <>
      <section className="h-screen max-h-screen" id="about">
        <div className="md:w-3/4 h-full px-4 md:px-0 mx-auto grid grid-cols-1 md:grid-cols-2">
          <div className="flex items-center order-2 md:order-1">
            <div
              data-aos="fade-right"
              data-aos-delay="1000"
              data-aos-duration="2000"
            >
              <p
                className={[
                  "p-4 border-4 border-slate-400 rounded-b-2xl rounded-ss-3xl bg-white text-2xl text-slate-600 relative",
                  "before:absolute before:border-4 before:border-slate-300 before:-left-8 before:right-8 before:-top-8 before:bottom-8 before:-z-10 before:rounded-ss-3xl before:rounded-es-2xl before:bg-neutral-100 before:invisible md:before:visible",
                ].join(" ")}
              >
                <span className="text-3xl block font-medium dark:text-slate-100 rounded-3xl">
                  About Me
                </span>
                <span className="">
                  I'm a Freelancer Front-end Developer with over 12 years of
                  experience. I'm from San Francisco. I code and create web
                  elements for amazing people around the world. I like work with
                  new people. New people are new experiences.
                </span>
              </p>
            </div>
          </div>
          <div className="grid place-content-center order-1 md:order-2">
            <div className="shadow-inner-extended rounded-full border-2 border-slate-400 overflow-hidden relative">
              <img
                className="h-[60dvmin] aspect-square object-cover object-center relative -z-10"
                data-aos="flip-right"
                data-aos-duration="3000"
                src="https://source.unsplash.com/random/300x400"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
