import { useEffect, useRef } from "react";
import { Link, Outlet } from "react-router-dom";
import contexts from "../contexts";
import { Navbar, useTheme, Footer } from "flowbite-react";

export default function Default() {
  const {
    state: { status },
  } = contexts.app.use();
  if (status === "loading") return <>loading</>;
  if (status === "normal")
    return (
      <>
        <Header />
        <Outlet />
        <FooterComponent />
      </>
    );
}

function Header() {
  const navRef = useRef(null);
  const { mode } = useTheme();
  useEffect(() => {
    window.onscroll = function () {
      let navbar;
      if (navRef.current) navbar = navRef.current;
      if (!navbar) return;
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        navbar.style.backgroundColor = "white";
        navbar.style.color = mode === "dark" ? "white" : "black";
      } else {
        navbar.style.backgroundColor = "#0003";
        navbar.style.color = "white";
      }
    };
  }, []);
  return (
    <header
      ref={navRef}
      className="bg-[#0003] text-white fixed inset-x-0 z-50 dark:text-white"
    >
      <Navbar fluid className="bg-transparent">
        <Navbar.Brand
          as={Link}
          to="/"
          className="text-3xl tracking-tighter font-medium"
        >
          Portfolio
        </Navbar.Brand>
        {/* <DarkThemeToggle />
         */}

        <Navbar.Toggle />
        <Navbar.Collapse>
          <Navbar.Link as={Link} className="text-inherit" to="/">
            Home
          </Navbar.Link>
          <Navbar.Link as={Link} className="text-inherit" to="/albums">
            Portfolio
          </Navbar.Link>
          <Navbar.Link href="#about" className="text-inherit">
            About
          </Navbar.Link>
          <Navbar.Link href="#contact" className="text-inherit">
            Contact
          </Navbar.Link>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}

function FooterComponent() {
  return (
    <>
      <Footer container>
        <div className="w-full text-center">
          <div className="w-full justify-between sm:flex sm:items-center sm:justify-between">
            <Footer.Brand
              href="#"
              src="https://infinarygeeks.com.np/assets/logo.png"
              alt="Infinary Geeks Logo"
              name="Portfolio"
            />
            <Footer.LinkGroup>
              <Footer.Link href="#about">About</Footer.Link>
              <Footer.Link href="#">Privacy Policy</Footer.Link>
              <Footer.Link href="#">Licensing</Footer.Link>
              <Footer.Link href="#">Contact</Footer.Link>
            </Footer.LinkGroup>
          </div>
          <Footer.Divider />
          <Footer.Copyright
            href="#"
            by="Infinary Geeks Pvt. Ltd."
            year={2023}
          />
        </div>
      </Footer>
    </>
  );
}
