//
import AppContextProvider, { AppContext, useAppContext } from "./Appcontext";

const contexts = {
  app: {
    provider: AppContextProvider,
    use: useAppContext,
    context: AppContext,
  },
};

export default contexts;
