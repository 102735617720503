import { Routes, Route } from "react-router-dom";
import "../node_modules/aos/dist/aos.css";
import Aos from "aos";

import layouts from "./layouts";
import pages from "./pages";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <Routes>
        <Route path="/" element={<layouts.default />}>
          <Route index exact element={<pages.home />} />
        </Route>
        <Route path="*" element={<>not found</>} />
      </Routes>
    </>
  );
}

export default App;
