import About from "./About";
import Banner from "./Banner";
import Featured from "./Featured";

export default function Home() {
  return (
    <>
      <Banner />
      <Featured />
      <About />
    </>
  );
}
