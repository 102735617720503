import { Flowbite } from "flowbite-react";
import { createContext, useContext, useReducer } from "react";

const initialState = {
  status: "normal",
};

function reducer(state, action) {
  switch (action.type) {
    case "loading":
    case "normal":
    case "notfound":
      return { ...state, status: action.type };
    default:
      return initialState;
  }
}

export const AppContext = createContext({
  state: initialState,
  dispatch: null,
});

export function useAppContext() {
  const context = useContext(AppContext);
  return { ...context };
}

function AppContextProvider({ children }) {
  const [state, Dispatch] = useReducer(reducer, initialState);
  function dispatch(type, payload = null) {
    return Dispatch({ type, ...(payload && { payload }) });
  }
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Flowbite>
        <>{children}</>
        <div className="fixed right-0 top-1/2"></div>
      </Flowbite>
    </AppContext.Provider>
  );
}

export default AppContextProvider;
