import Masonry from "react-masonry-css";
import classes from "./featured.module.css";

export default function Featured() {
  return (
    <section className="bg-slate-100 dark:bg-slate-800">
      <div className="md:w-3/4 mx-auto overflow-hidden">
        <h1 className="text-4xl my-16 text-center font-medium dark:text-neutral-100">
          Featured Work
        </h1>
        <Masonry
          breakpointCols={{
            default: 4,
            1100: 3,
            700: 2,
            500: 1,
          }}
          className={classes.myMasonryGrid}
          columnClassName={classes.myMasonryGridColumn}
        >
          {/* array of JSX items */}
          <img
            data-aos="fade-right"
            data-aos-duration="2000"
            src="https://source.unsplash.com/random/400x300"
            alt=""
          />
          <img
            data-aos="fade-down"
            data-aos-duration="2000"
            src="https://source.unsplash.com/random/500x400"
            alt=""
          />
          <img
            data-aos="fade-down"
            data-aos-duration="2000"
            src="https://source.unsplash.com/random/800x300"
            alt=""
          />
          <img
            data-aos="fade-left"
            data-aos-duration="2000"
            src="https://source.unsplash.com/random/700x600"
            alt=""
          />
          <img
            data-aos="fade-right"
            data-aos-duration="2000"
            src="https://source.unsplash.com/random/300x400"
            alt=""
          />
          <img
            data-aos="fade-up"
            data-aos-duration="2000"
            src="https://source.unsplash.com/random/400x500"
            alt=""
          />
          <img
            data-aos="fade-up"
            data-aos-duration="2000"
            src="https://source.unsplash.com/random/500x800"
            alt=""
          />
          <img
            data-aos="fade-left"
            data-aos-duration="2000"
            src="https://source.unsplash.com/random/600x700"
            alt=""
          />
        </Masonry>
      </div>
    </section>
  );
}
