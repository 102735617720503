import { Button } from "flowbite-react";

export default function Banner() {
  return (
    <section className="h-screen overflow-hidden flex items-center text-center relative isolate">
      <div className="absolute inset-0 bg-[#3338]" />
      <div
        className="absolute inset-0 bg-cover bg-center bg-no-repeat -z-10"
        style={{
          backgroundImage: "url('https://source.unsplash.com/random/1600x900')",
        }}
        data-aos="fade-left"
        data-aos-delay="1000"
        data-aos-duration="500"
      ></div>
      <div className="container mx-auto text-white">
        <h1
          className="text-4xl font-extrabold leading-tight mb-4"
          data-aos="fade-up"
        >
          Welcome to My Photography Portfolio
        </h1>
        <p className="text-lg mb-8" data-aos="fade-up" data-aos-delay="300">
          Capturing moments, one frame at a time.
        </p>
        <Button
          className=" text-white font-semibold px-6  rounded-full inline-block transition duration-300 ease-in-out"
          data-aos="fade-up"
          data-aos-delay="500"
        >
          View Portfolio
        </Button>
      </div>
    </section>
  );
}
